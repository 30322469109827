/* App.css */

.container {
  /* Agrega una altura al contenedor para que el elemento sticky funcione */
  height: 100%; /* 100% del alto de la ventana */
  overflow: auto; /* Habilita el desplazamiento si el contenido excede la altura */
  background-color: #fff;
}

/* Estilos para el footer */
.footer {
  background-color: transparent;
  color: lightgray;
  padding: 10px;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
}

.fotter-amor {
  position: fixed;
  bottom: 0px;
  background-color: white;
  color: gray;
  font-size: 0.9em;
  text-align: center; /* Centra horizontalmente */
  line-height: 20px; /* Ajusta la altura verticalmente */
  width: 100%; /* Opcional: asegura que ocupe todo el ancho disponible */
}

.brands {
  background-color: transparent;
  font-size: medium;
  color: #777;
  padding-top: 15px;
  text-align: center; /* Centra horizontalmente */
}

.sticky-div {
  position: sticky;
  top: 0;
  color: #fff;
  padding: 10px;
  height: 30px;
  background-color: #000;
  text-align: start;
  vertical-align: middle;
  font-family: 'Rubik', sans-serif;
  display: flex; /* Utilizamos flexbox para alinear los divs horizontalmente */
  justify-content: space-between;
}

.nombre, .xsocial {
  flex: 1; 
  padding: 10px; 
}

.nombre {
  text-align: start;
  font-family: 'Comfortaa', cursive;
  color: #fff;

}

.xsocial {
  text-align: end;

}

.imagen {
  padding-top: 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 150px;
}

.twitter-link {
  text-decoration: none; 
  color: white; 
}


.imagen-logo {
  padding-top: 0px;
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Establece el radio para hacerlo circular */
}

.efecto{
  padding-top: 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 50px;
}

.typing-animation {
  font-size: 14px;
  border-right: 2px solid #000;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
}

.cursor {
  display: inline-block;
  width: 10px;
  height: 24px;
  vertical-align: text-bottom;
  background-color: #000;
  animation: blink-caret 0.75s step-end infinite;
}

.contenedor-info {
  display: flex; /* Usamos Flexbox para el diseño */
  justify-content: space-between; /* Espacio uniforme entre los elementos */
  padding-top: 33px;
}

.lado-izquierdo, .lado-derecho {
  flex: 1; /* Cada lado ocupa el 20% del ancho */
  background-color: #ffffff; /* Color de fondo para los lados */
}

.centro {
  flex: 3; /* El centro ocupa el 60% del ancho */
  background-color: #ffffff; /* Color de fondo para el centro */
  text-align: justify;

}



.contenedor-sobre-mi {
  display: flex; /* Usamos Flexbox para el diseño */
  padding-top: 40px;
  text-align: start;
}

.columna-sobremi-izq {
  flex: 1; 
  font-size: medium;
  color: #777;
}

.columna-sobremi-der {
  flex: 1; 
  text-align: end;
  font-size: medium;
  color: #777;
}

.columna-sobremi-centro {
  flex: 1.34; 
  text-align: center;
  font-size: medium;
  color: #777;
}

/* Estilos para el botón */
.boton-pequeno {
  width: auto;
  height: 25px;
  border: none;
  padding: 5px 5px; /* Añade espacio interno para el texto */
  border-radius: none; /* Hace que el botón sea redondeado */
  background-color: #fff; /* Color de fondo del botón */
  color: #777; /* Color del texto */
  font-size: medium; /* Tamaño del texto */
  text-align: center; /* Centra el texto horizontalmente */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}

/* Estilos para dispositivos móviles (pantallas más pequeñas) */
@media screen and (max-width: 768px) {
  .contenedor-sobre-mi {
    flex-direction: column; /* Cambiamos la dirección del flex a columna */
  }

  /* Restauramos el ancho al 100% para que ocupen toda la pantalla verticalmente */
  .columna-sobremi-izq, .columna-sobremi-der, .columna-sobremi-centro {
    flex: 1;
    text-align: center;
    padding-top: 5px; /* Agrega un espacio en la parte superior */
    margin-bottom: 10px; /* Agrega margen en la parte inferior */
  }
}


/* Estilo para el fondo oscurecido */
.modal-fondo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscurecido con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el fondo esté por encima de otros elementos */
}

/* Estilo para el modal */
.modal-contenedor {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Sombra para el modal */
  z-index: 1001; /* Asegura que el modal esté por encima del fondo oscurecido */
  color: #777;
  font-size: x-small;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

.modal-cerrar {
  text-align: end;
  cursor:pointer;
}

.enviarnombre{
  background-color: white;
  border:none;
  color: black;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  color: #FC744F;
  font-weight: bold;
}

.error-mensaje {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}



/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&family=Cutive&family=Quicksand&family=Rubik:wght@500&display=swap');


